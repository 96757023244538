import moment from "moment";
import { DatePicker, Select, Table } from "antd";
import { useState, useEffect } from "react";
import "moment/locale/vi";
import { ScoreActions } from "../../redux/reducers/Score/score";
import { useAppDispatch, useAppSelector } from "../../utils/hook";
import { RoomActions } from "../../redux/reducers/Room/room";
import { UserActions } from "../../redux/reducers/User/user";

moment.locale("vi");

const ScoreFind = () => {
  const { dataScore } = useAppSelector((state) => state.score);
  console.log(dataScore, "dataScore");

  const { Option } = Select;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(RoomActions.GET_LIST_ROOM_REQUREST());
  }, [dispatch]);
  const { data } = useAppSelector((state) => state.room);
  const [today, settoday] = useState(moment());
  useEffect(() => {
    dispatch(ScoreActions.REQUREST_SCORETODAYLIST({ data: moment() }));
  }, [dispatch]);
  const SelectRoom = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    console.log(dataScore, values, "1213");
  };
  function onChange(date: any, dateString: any) {
    settoday(date);
    dispatch(ScoreActions.REQUREST_SCORETODAYLIST({ data: date }));
  }

  const columns = [
    {
      title: "Tên nhân viên",
      dataIndex: "Name",
      key: "Name",
      render: (Name: any) => <li>{Name == null ? "" : Name.Name}</li>,
    },
    {
      title: "Điểm tự chấm",
      dataIndex: "MyPoint",
      key: "MyPoint",
      render: (MyPoint: Number) => MyPoint,
    },
    {
      title: "Điểm Trung Bình Phòng",
      dataIndex: "AveragePoint",
      key: "AveragePoint",
      render: (AveragePoint: Number) => AveragePoint,
    },
    {
      title: "Điểm lãnh đạo",
      dataIndex: "LeadPoint",
      key: "LeadPoint",
      render: (LeadPoint: Number) => LeadPoint,
    },
    {
      title: "Cập nhật lần cuối",
      dataIndex: "Time",
      key: "Time",
      render: (Time: Date) => moment(Time).subtract(10, "days").calendar(),
    },
    {
      title: "Phòng",
      dataIndex: "Room",
      key: "Room",
    },
  ];

  return (
    <>
      <div>
        <DatePicker onChange={onChange} picker="month" defaultValue={today} />
        <Select
          style={{ width: "20%" }}
          allowClear
          placeholder="Chọn phòng"
          onChange={SelectRoom}
        >
          {data.map((item) => {
            return (
              <Option key={item._id} value={item.room_name}>
                {item.room_name}
              </Option>
            );
          })}
        </Select>
      </div>
      <Table
        dataSource={dataScore}
        columns={columns}
        rowKey={(record) => record._id}
      />
    </>
  );
};

export default ScoreFind;
