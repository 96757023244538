/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { Table, Space, message, Modal } from "antd";
import moment from "moment";
import "moment/locale/vi";
import styles from "./table.module.css";
import { useAppDispatch } from "../../utils/hook";
import { HomeActions } from "../../redux/reducers/Meetups/home";

moment.locale("vi");
interface IDataTemp {
  Address: string;
  MonitoringRoom: string;
  UserInvitation: string;
  Userlead: [];
  User: [];
  Date: Date;
  description: string;
  id: string;
  _id: string;
  Time: Date;
  title: string;
}

interface IProps {
  data: IDataTemp[];
  onOpenUpdate: (item: any) => void;
}

const TableComponent: FC<IProps> = ({ data, onOpenUpdate }) => {
  const role = localStorage.getItem("role");
  const dispatch = useAppDispatch();
  const [isItem, setisItem] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (e: any) => {
    setisItem(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(
      HomeActions.DELETE_TODO_REQUREST({
        id: isItem._id,
        cb: (res: any) => {
          if (res) {
            message.success("Xoá thành công");
          }
        },
      })
    );
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  if (role === "admin" || role === "manager") {
    const columns = [
      {
        title: "Ngày họp",
        dataIndex: "Date",
        key: "Date",
        render: (Date: Date) => (
          <p style={{ color: "red" }}>
            {moment(Date).format("dddd,") + moment(Date).format(" LL")}
          </p>
        ),
      },
      {
        title: "Thời gian",
        dataIndex: "Time",
        key: "Time",
        render: (Time: Date) => moment(Time).format("LT"),
      },
      {
        title: "Tiêu đề cuộc họp",
        dataIndex: "title",
        key: "title",
        render: (title: string) => <a>{title}</a>,
      },
      {
        title: "Nội Dung",
        dataIndex: "description",
        key: "description",
      },

      {
        title: "Địa chỉ",
        dataIndex: "Address",
        key: "Address",
      },
      {
        title: "Lãnh đạo cuộc họp",
        dataIndex: "Userlead",
        key: "Userlead",
        render: (record: any) => {
          return (
            <ul>
              {record.map((ele: any) => (
                <li key={ele._id}>{ele.Name}</li>
              ))}
            </ul>
          );
        },
      },
      {
        title: "Người tham gia",
        dataIndex: "User",
        key: "User",
        render: (record: any) => {
          return (
            <ul>
              {record.map((ele: any) => (
                <li key={ele._id}>{ele.Name}</li>
              ))}
            </ul>
          );
        },
      },
      {
        title: "Phòng giám sát",
        dataIndex: "MonitoringRoom",
        key: "MonitoringRoom",
      },
      {
        title: "Khách mời",
        dataIndex: "UserInvitation",
        key: "UserInvitation",
      },
      {
        title: "Hành Động",
        key: "action",
        render: (text: string, record: IDataTemp) => (
          <Space size="middle">
            <a
              onClick={(e) => {
                e.preventDefault();
                onOpenUpdate(record);
              }}
            >
              Sửa
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                {
                  showModal(record);
                }
              }}
            >
              Xoá
            </a>
          </Space>
        ),
      },
    ];

    return (
      <>
        <Modal
          title="THÔNG BÁO"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Bạn có muốn xoá không?</p>
        </Modal>
        <Table
          columns={columns}
          rowKey="_id"
          dataSource={data}
          bordered={true}
          className={styles.customSelect}
        />
      </>
    );
  } else {
    const columns = [
      {
        title: "Tiêu đề cuộc họp",
        dataIndex: "title",
        key: "title",
        render: (title: string) => <a>{title}</a>,
      },
      {
        title: "Nội Dung",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Ngày họp",
        dataIndex: "Date",
        key: "Date",
        render: (Date: Date) => (
          <p style={{ color: "red" }}>
            {moment(Date).format("dddd,") + moment(Date).format(" LL")}{" "}
          </p>
        ),
      },
      {
        title: "Thời gian",
        dataIndex: "Time",
        key: "Time",
        render: (Time: Date) => moment(Time).format("LT"),
      },
      {
        title: "Địa chỉ",
        dataIndex: "Address",
        key: "Address",
      },
      {
        title: "Lãnh đạo cuộc họp",
        dataIndex: "Userlead",
        key: "Userlead",
        render: (record: any) => {
          return (
            <ul>
              {record.map((ele: any) => (
                <li key={ele._id}>{ele.Name}</li>
              ))}
            </ul>
          );
        },
      },
      {
        title: "Người tham gia",
        dataIndex: "User",
        key: "User",
        render: (record: any) => {
          return (
            <ul>
              {record.map((ele: any) => (
                <li key={ele._id}>{ele.Name}</li>
              ))}
            </ul>
          );
        },
      },
      {
        title: "Phòng giám sát",
        dataIndex: "MonitoringRoom",
        key: "MonitoringRoom",
      },
      {
        title: "Khách mời",
        dataIndex: "UserInvitation",
        key: "UserInvitation",
      },
    ];

    return (
      <Table
        columns={columns}
        rowKey="_id"
        dataSource={data}
        bordered={true}
        className={styles.customSelect}
      />
    );
  }
};
TableComponent.defaultProps = {
  data: [],
};
export default TableComponent;
