/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { Table, Space, Modal, message } from "antd";
import { useAppDispatch } from "../../utils/hook";
import { UserActions } from "../../redux/reducers/User/user";

interface IDataUser {
  ID_NB: number;
  Name: string;
  Phone_Number: string;
  Role: string;
  Status: string;
  email: string;
  job_type: string;
  user_room: string;
  password: string;
}

interface IProps {
  data: IDataUser[];
  onOpenUpdateUser: (item: any) => void;
}

const TableComponent: FC<IProps> = ({ data, onOpenUpdateUser }) => {
  const dispatch = useAppDispatch();
  const [isItem, setisItem] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const role = localStorage.getItem("role");
  const showModal = (e: any) => {
    setisItem(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(
      UserActions.DELETE_TODO_REQUREST({
        id: isItem._id,
        cb: (res: any) => {
          if (res) {
            message.success("Xoá thành công");
          }
        },
      })
    );
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  if (role === "admin") {
    const columns = [
      {
        title: "ID Nội bộ",
        dataIndex: "ID_NB",
        key: "ID_NB",
      },
      {
        title: "Họ và tên",
        dataIndex: "Name",
        key: "Name",
        render: (title: string) => <a>{title}</a>,
      },
      {
        title: "Số điện thoại",
        dataIndex: "Phone_Number",
        key: "Phone_Number",
      },

      {
        title: "Cấp bậc",
        dataIndex: "Role",
        key: "Role",
      },
      {
        title: "Loại hình công việc",
        dataIndex: "job_type",
        key: "job_type",
      },
      {
        title: "Khoa/Phòng",
        dataIndex: "user_room",
        key: "user_room",
      },
      {
        title: "Trạng thái",
        dataIndex: "Status",
        key: "Status",
      },

      {
        title: "Hành động",
        key: "action",
        render: (text: string, record: IDataUser) => (
          <Space size="middle">
            <a
              onClick={(e) => {
                e.preventDefault();
                onOpenUpdateUser(record);
              }}
            >
              Sửa
            </a>
            <a
              onClick={(e) => {
                // eslint-disable-next-line no-lone-blocks
                {
                  showModal(record);
                }
              }}
            >
              Xoá
            </a>
          </Space>
        ),
      },
    ];
    return (
      <>
        <Modal
          title="THÔNG BÁO"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Bạn có muốn xoá không?</p>
        </Modal>
        <Table columns={columns} rowKey="_id" dataSource={data} />
      </>
    );
  } else {
    const columns2 = [
      {
        title: "ID Nội bộ",
        dataIndex: "ID_NB",
        key: "ID_NB",
      },
      {
        title: "Họ và tên",
        dataIndex: "Name",
        key: "Name",
        render: (title: string) => <a>{title}</a>,
      },
      {
        title: "Số điện thoại",
        dataIndex: "Phone_Number",
        key: "Phone_Number",
      },

      {
        title: "Cấp bậc",
        dataIndex: "Role",
        key: "Role",
      },
      {
        title: "Loại hình công việc",
        dataIndex: "job_type",
        key: "job_type",
      },
      {
        title: "Khoa/Phòng",
        dataIndex: "user_room",
        key: "user_room",
      },
      {
        title: "Trạng thái",
        dataIndex: "Status",
        key: "Status",
      },
    ];
    return <Table columns={columns2} rowKey="_id" dataSource={data} />;
  }
};
TableComponent.defaultProps = {
  data: [],
};
export default TableComponent;
